var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-prompt"
        }, [_c('div', {
          staticClass: "cyui-prompt-header"
        }, [_c('div', {
          staticClass: "cyui-prompt-pic"
        }, [_c('img', {
          directives: [{
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.prompt404,
            expression: "prompt404"
          }],
          attrs: {
            "alt": ""
          }
        })])]), _c('div', {
          staticClass: "cyui-prompt-body"
        }, [_c('div', {
          staticClass: "cyui-prompt-message"
        }, [_c('p', {
          staticClass: "cyui-prompt-message-title"
        }, [_vm._v("哎呀，网页溜走了...")]), _c('p', [_vm._v("您浏览的页面貌似不存在")])]), _c('div', {
          staticClass: "cyui-prompt-button"
        })])])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };